/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Box } from '@chakra-ui/react';
import { PageStyles } from '.';
import SEO from '../components/SEO';
import { GenericHeader } from '../components/GenericHeader';
import { AboutStyles } from './about';
import { Contact } from '../components/Contact';
import { DesktopWrapper } from '../templates/Post';

export default function ContactPage() {
  return (
    <>
      <SEO title="Contact | Yuks" />
      <PageStyles>
        <AboutStyles>
          <Box className="content-wrapper" marginBottom="10px">
            <DesktopWrapper>
              <GenericHeader header="Contact Yuks" />
            </DesktopWrapper>
            <h1>Fill out the form below to contact us</h1>
            <Box mt="5px">
              <Contact />
            </Box>
          </Box>
        </AboutStyles>
      </PageStyles>
    </>
  );
}
