/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Text, Box } from '@chakra-ui/react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { PageStyles } from '.';
import SEO from '../components/SEO';
import { GenericHeader } from '../components/GenericHeader';
import { DesktopWrapper } from '../templates/Post';

export const AboutStyles = styled.div`
  .content-wrapper {
    max-width: 700px;
    @media (max-width: 414px) {
      margin: 0.5rem 0 1rem 0;
    }
  }
  h1,
  h2 {
    font-size: 20px;
    font-weight: 700;
  }
  h2 {
    margin-top: 2rem;
  }
  .paragraph {
    margin-top: 1rem;
  }
  a {
    font-weight: 600;
    color: var(--facebook);
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default function AboutPage() {
  return (
    <>
      <SEO title="About | Yuks" />
      <PageStyles>
        <AboutStyles>
          <Box className="content-wrapper">
            <DesktopWrapper>
              <GenericHeader header="About Yuks" />
            </DesktopWrapper>
            <h1>What is Yuks.co?</h1>
            <Text mt="5px">
              Yuks.co is a comedy portal (in the form of a website) that enables
              you to connect with your favorite comedians on a deeper level.
              Learn more about their creative processes, strangest fan
              encounters, childhood memories and more.
            </Text>
            <h2>What's up with the name?</h2>
            <Text mt="5px">
              The Merriam-Webster dictionary defines a{' '}
              <a
                href="https://www.merriam-webster.com/dictionary/yuk"
                target="_blank"
              >
                yuk
              </a>{' '}
              as slang for 'laugh' and gives the example 'did it just for yuks'.
              {'  '}
              <a href="https://twitter.com/marknorm" target="_blank">
                Mark Normand
              </a>{' '}
              is also fond of punctuating his tweets with a reference to 'yuks'
              or 'yuk it up' etc. such as in his promotional tweet for his 2020
              special{' '}
              <a
                href="https://twitter.com/marknorm/status/1266050610333462530"
                target="_blank"
              >
                'Out to Lunch'
              </a>
              . There's also a famous comedy club in Toronto, Canada called{' '}
              <a href="https://www.yukyuks.com/" target="_blank">
                Yuk Yuk's
              </a>
              .
            </Text>
            <h2>Can I contribute?</h2>
            <Text mt="5px">
              We need all the help we can get! If you feel moved, you can{' '}
              <Link to="/create-post">submit a post for a comedian</Link>, or{' '}
              <Link to="/add-fact">add more details to a comedian’s bio</Link>.
            </Text>
            <h2>Self promotion?</h2>
            <Text mt="5px">
              We previously made a website for one of our favs,{' '}
              <a href="https://www.harriswittels.wiki" target="__blank">
                Harris Wittels
              </a>
              . Would recommend checking out his work if you've never heard of
              him, he's one of the best.
            </Text>
            <Text mt="5px">
              We also run a site for people to listen to the Joe Rogan
              Experience who may have forgot about the podcast entirely since he
              moved to Spotify, called{' '}
              <a href="https://ogjre.com" target="__blank">
                OG JRE
              </a>
              . It has a Youtube-like vibe with a comment section, timestamps
              for each episode, and more.
            </Text>
          </Box>
        </AboutStyles>
      </PageStyles>
    </>
  );
}
