/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import { Box, Button, Grid, Flex, Input, FormControl, Textarea, FormHelperText } from '@chakra-ui/react';
import emailjs from 'emailjs-com';
import React, { useState } from 'react';
import { SubmitAdviceWrapperStyles } from './SubmitPost';

export const Contact = () => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [source, setSource] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const serviceId = process.env.GATSBY_EMAILJS_SERVICE_ID;
  const templateId = process.env.GATSBY_EMAILJS_TEMPLATE_ID;
  const userId = process.env.GATSBY_EMAILJS_USER_ID;

  const sendEmail = (e) => {
    setLoading(true);
    e.preventDefault();
    emailjs.sendForm(serviceId, templateId, e.target, userId);
    setTimeout(() => {
        setLoading(false);
        setTitle('');
        setContent('');
        setSource('');
        setName('');
        setEmail('');
        setSuccess(true);
    }, 1000);
  }

  return (
    <Grid gridTemplateColumns="1fr">
      {success && (
        <SubmitAdviceWrapperStyles>
          <Box lineHeight="1.25" fontWeight="500" color="#4056a1">Thanks for the message.  We'll get back to you as soon as we can.</Box>
        </SubmitAdviceWrapperStyles>
      )}
      {!success && (
        <SubmitAdviceWrapperStyles>
          <form onSubmit={sendEmail}>
              <fieldset disabled={loading} style={{ border: 'none' }}>
                <FormControl id="header" style={{ display: 'none'}}>
                  <Input type="text" name="header" value="Contact page" />
                </FormControl>
                <FormControl id="name">
                  <Input type="text" placeholder="your name" autoComplete="off" name="name" value={name} onChange={e => setName(e.target.value)} />
                </FormControl>
                <FormControl id="email" mt={4}>
                  <Input type="email" placeholder="your email" autoComplete="off" name="email" value={email} onChange={e => setEmail(e.target.value)} />
                </FormControl>
                <FormControl id="title" mt={4}>
                  <Input type="text" autoComplete="off" placeholder="subject" name="title" value={title} onChange={e => setTitle(e.target.value)} />
                </FormControl>
                <FormControl id="content" mt={4}>
                  <Textarea placeholder="message" autoComplete="off" name="content" required value={content} onChange={e => setContent(e.target.value)} />
                </FormControl>
                <Flex mt={4}>
                  <Button
                    padding="18px 15px"
                    fontSize="15px"
                    type="submit"
                    colorScheme="facebook"
                    isLoading={loading}
                  >
                  Submit
                  </Button>
                </Flex>
              </fieldset>
          </form>
        </SubmitAdviceWrapperStyles>
      )}
    </Grid>
  );
};
